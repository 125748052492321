import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiUtil from '../../utils/apiUtils';
import { googleSignIn } from '../../store/slices/AuthSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GoogleAuth = () => {
  const clientId = "707359055239-cpqs9e5hv9spppbnrqkj28f7d7k7hiqc.apps.googleusercontent.com";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registerUser = async (credentials) => {
    try {
      await dispatch(googleSignIn(credentials)).unwrap();
      toast.success('Logged in Successfully');
      navigate('/');
    } catch (error) {
      toast.error('Authentication failed. Please try again.');
    }
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          registerUser(credentialResponse);
        }}
        onError={() => {
          toast.error('Google login failed. Please try again.');
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
